import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Style.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Home from './components/Home';
import Materials from './pages/Materials';
import Faq from './pages/Faq';
import Contact from './pages/Contact';
import Company from './pages/Company';
import Careers from './pages/Careers';
import NewArrivals from './products/NewArrivals';
import GetSample from './pages/GetSample';
import BookConsultation from './pages/BookConsultation';
import Products from "./components/Products";

function App() {
  window.addEventListener('scroll', function () {
    var body = document.querySelector('body');
    var scrollPosition = window.scrollY;
    if (scrollPosition > 100) {
      body.classList.add('scrolled');
    } else {
      body.classList.remove('scrolled');
    }

    const header = document.getElementById('header');
    if (window.scrollY > 50) {
      header.classList.add('fixed');
    } else {
      header.classList.remove('fixed');
    }
  });

  useEffect(() => {
    AOS.init({ duration: 1000 });
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/" element={<Navigate replace to="/home" />} />
          <Route path="/materials" element={<Materials />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/Company" element={<Company />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/products/:type" element={<NewArrivals />} />
          <Route path="/components/Products" element={<Products />} />
          <Route path="/get-a-sample" element={<GetSample />} />
          <Route path="/book-a-consultation" element={<BookConsultation />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
