import React, { useEffect, useRef } from 'react';

const ContactForm = () => {
  const formContainerRef = useRef(null);

  useEffect(() => {
    const initializeScript = () => {
      // Check if the script is already added to prevent duplication
      if (document.getElementById("fs_76e46b1b6a4fed8009cf0ff3d26c7c7c42d0c016d17224e429eaaf3df00a4657")) {
        return;
      }

      const script = document.createElement("script");
      script.src = "https://astronglobal.myfreshworks.com/crm/sales/web_forms/76e46b1b6a4fed8009cf0ff3d26c7c7c42d0c016d17224e429eaaf3df00a4657/form.js";
      script.crossOrigin = "anonymous";
      script.id = "fs_76e46b1b6a4fed8009cf0ff3d26c7c7c42d0c016d17224e429eaaf3df00a4657";

      if (formContainerRef.current) {
        formContainerRef.current.appendChild(script);
      }
    };

    if (document.readyState === "complete") {
      initializeScript();
    } else {
      const handleLoad = () => initializeScript();
      window.addEventListener("load", handleLoad);
      return () => window.removeEventListener("load", handleLoad);
    }
  }, []);

  return (
    <div>
      {/* <h1>Contact Us</h1> */}
      <div ref={formContainerRef}></div>
    </div>
  );
};

export default ContactForm;
