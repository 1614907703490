import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';

const Footer = () => {
    return (
        <>
            <footer className="dark-bg">
                <Container fluid="xl">
                    <Row>
                        <Col lg={3} xs="6">
                            <div className="footer-widget footer-logo">
                                <Link to="/" className="footer-logo"><img src="../assets/images/logo-light.svg" alt="astron logo" /></Link>
                                <p>Astron Global is your source for quality granite monuments from India.
                                Founded in 2001 by <strong>Ravi Shetty and Saravanan</strong>.</p>
                                <Link to="/company" className="readmore">Know More</Link>
                                <div className="social-icons">
                                    <ul>
                                        <li><Link to="https://www.facebook.com/AstronGlobal/" target="_blank"><Icon icon="fa:facebook" /></Link></li>
                                        <li><Link to="https://www.instagram.com/astron_global/" target="_blank"><Icon icon="lets-icons:insta-fill" /></Link></li>
                                        <li><Link to="https://www.youtube.com/@Astron_Global" target="_blank"><Icon icon="mingcute:youtube-fill" /></Link></li>
                                        <li><Link to="https://x.com/Astron_Global" target="_blank"><Icon icon="prime:twitter" /></Link></li>
                                        <li><Link to="https://www.linkedin.com/company/astron-global---india/" target="_blank"><Icon icon="formkit:linkedin" /></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} xs="6">
                            <div className="footer-widget">
                                <h2>Useful Links</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/products/new-arrivals">Products</Link></li>
                                    <li><Link to="/materials">Materials</Link></li>
                                    <li><Link to="/faq">FAQ</Link></li>
                                    <li><Link to="/careers">Careers</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={3} sm="6">
                            <div className="footer-widget">
                                <h2>Contact Information</h2>
                                <p className="contact-info"><span><Icon icon="ph:map-pin-line" /></span>F 47, H, First Main Road,<br />Anna Nagar, <br />Chennai - 600 102, India.</p>
                                <p className="contact-info"><span><Icon icon="ph:phone" /></span><Link to="tel:+91 9840299626">+91 9840299626</Link></p>
                                <p className="contact-info"><span><Icon icon="mage:email" /></span><Link to="mailto:info@astronglobal.com">info@astronglobal.com</Link></p>
                            </div>
                        </Col>
                        <Col lg={3} sm="6">
                            <Link to="https://wa.me/919840299626?text=Hello%20Astron%20Global,I%27m%20Interested%20On%20Your%20Products." target="_blank" className="enquiry-link">
                                {/* <span className="blink"><img src="../assets/images/icons/whatsapp-icon.svg" alt="whatsapp-icon" /></span> */}
                                Call FOR Quick Enquiry <label>+91 9840299626</label>
                            </Link>

                            <Link to="/get-a-sample" className="sample-link mt-4"><span><Icon icon="mingcute:gift-fill" /></span> Get a Sample</Link>
                            <Link to="/book-a-consultation" className="sample-link mt-4"><span><Icon icon="ion:call" /></span> Book a Consultation</Link>
                            <div className="association-logo">
                                <p>Association</p>
                                <ul>
                                    <li><Link><img src="../assets/images/association/mbna.png" alt="MBNA" title="MBNA" /></Link></li>
                                    <li><Link><img src="../assets/images/association/capexil.png" alt="Capexil" title="Capexil" /></Link></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <div className="copyright">
                        <p className="mb-0">Copyright © 2025 Astron Global. All Rights Reserved.</p>
                    </div>
                </Container>
            </footer>
        </>
    );
};

export default Footer;
