import { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Link } from "react-router-dom";
// Add Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
      <Header />
      <section className="section inner alt-bg">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div className="heading text-center">
                <h2>CONTACT US</h2>
                <p>Our commitment stands strong to help our clients be more successful<br />by offering a line of functional
                  memorial products to better serve our clients.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section">
        <Container>
          <Row className="">
            <Col lg={6}>
              <div className="contact-pageinfo border-0">
                <h5>GET IN TOUCH WITH US</h5>
                <p>Book your appointment with us</p>

                <div className="mt-5">
                  <h6><Icon icon="ic:baseline-call" /> Call us now</h6>
                  <p>Talk to us and see how we can work together<br /><Link to="">+91 9840299626</Link></p>
                </div>

                <div className="mt-5">
                  <h6><Icon icon="mage:email-opened-fill" /> Your Message</h6>
                  <p>We're usually replying within 24 hours<br /><Link to="">info@astronglobal.com</Link></p>
                </div>

                <div className="mt-5">
                  <h6><Icon icon="mingcute:location-fill" /> Our Location</h6>
                  <p>F 47, H, First Main Road, Anna Nagar,<br />Chennai - 600 102, Tamilnadu, India.</p>
                </div>
              </div>
            </Col>

            <Col lg={6}>
              <div className="contact-pageinfo formtablet">
                <h5 className="mb-4">Send a Message</h5>
                <ContactForm />
              </div>
            </Col>
          </Row>          
        </Container>
        <Container className="mt-5">
          <div className="border-design">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.1936887896!2d80.22046727492041!3d13.08690788723916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525da00fffffff%3A0xf7930f75a259843!2sAstron%20Global!5e0!3m2!1sen!2sin!4v1720096976003!5m2!1sen!2sin" width="100%" height="450" frameBorder="0"
              title="MapFrame" style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </Container>
      </section>

      <Footer />
    </div>
  );
};

export default Contact;
