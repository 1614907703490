import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";


const MaterialsHome = () => {
    return (
        <>
            <section className="section material">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <div className="heading text-center mb-5">
                                <h2>Wide Choice of Colors</h2>
                                <p>We are providing wide range of colors for your selection</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col lg={12}>
                            <div class="materials-list">
                                <div class="box">
                                    <img src="../assets/images/materials/Bahama-Blue.jpg" alt="Bahama Blue" />
                                    <span>Bahama Blue</span>
                                </div>                                
                                <div class="box">
                                    <img src="../assets/images/materials/Cats-Eye-Brown.jpg" alt="Cats Eye Brown" />
                                    <span>Cats Eye Brown</span>
                                </div>
                                <div class="box">
                                    <img src="../assets/images/materials/Himalaya.jpg" alt="Himalaya" />
                                    <span>Himalaya</span>
                                </div>
                                <div class="box">
                                    <img src="../assets/images/materials/India-Black.jpg" alt="India Black" />
                                    <span>India Black</span>
                                </div>
                                <div class="box">
                                    <img src="../assets/images/materials/India-Red.jpg" alt="India Red" />
                                    <span>India Red</span>
                                </div>
                                <div class="box">
                                    <img src="../assets/images/materials/Indian-Aurora.jpg" alt="Indian Aurora" />
                                    <span>India Aurora</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="text-center mt-5">
                        <Button href="/Materials" variant="primary">View More</Button>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MaterialsHome;
