import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';

const About = () => {
    return (
        <>
            <section className="section about">
                <Container fluid="xl">
                    <Row>
                        <Col lg={5}>
                            <div className="about-img">
                                <Carousel fade interval={1000} controls className="aboutSlider">
                                    <Carousel.Item className="">
                                        <img src="../assets/images/about-big1.jpg" className="img-fluid" alt="about-1" />
                                    </Carousel.Item>
                                    <Carousel.Item className="">
                                        <img src="../assets/images/about-big2.jpg" className="img-fluid" alt="about-2" />
                                    </Carousel.Item>
                                    <Carousel.Item className="">
                                        <img src="../assets/images/about-big3.jpg" className="img-fluid" alt="about-3" />
                                    </Carousel.Item>
                                </Carousel>                                  
                                <img src="../assets/images/about-2.jpg" className="img-fluid img2" data-aos="fade-left" alt="about-2" />
                            </div>
                        </Col>
                        <Col lg={{ span: 6, offset: 1 }}>
                            <div className="about-cont">
                                <h2 className="text-uppercase mb-3">About <span className="">Astron Global</span></h2>
                                <h5 className="text-uppercase mb-4">25+ YEARS IN granite BUSINESS</h5>
                                <p className="running-text">Astron Global is your source for quality granite monuments from India.
                                Founded in 2001 by <strong>Ravi Shetty and Saravanan</strong>. We now serve a wide and loyal customer network across the <strong>USA,Canada,UK,Ireland,Germany,Australia and New Zealand.</strong></p>
                                <p className="running-text">The founders have more than three decades of experience in the <strong>granite industry</strong>. Astron Global leverages their expertise in all aspects of the business, from operating quarries to manufacturing and marketing monuments, to provide customers with a simplified and <strong>stress-free buying experience</strong>.</p>
                                {/* <p className="running-text">We guide customers through the entire process and take care of all shipping and inland logistics right up to <strong>delivery</strong> to your door.</p> */}
                                <div className="btns mt-4">
                                    <Button href="/Company" variant="primary">Know More</Button>
                                    <Button href="/contact-us" variant="outline dark">Contact Us</Button>                                    
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default About;
