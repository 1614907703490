import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Icon } from '@iconify/react';


const ProcessFlow = () => {
    return (
        <>
            <section className="section process-flow border-top">
                <Container fluid="xl">
                    <Row>
                        <Col lg={12}>
                            <div className="heading text-center mb-5">
                                <h2>ORDER PROCESS FLOW</h2>
                                <p>If you need further assistance or have any specific details to include, don’t hesitate to ask!</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="gx-0 justify-content-md-center">
                        <Col lg={2} md={4} sm={4} xs={6}>
                            <div className="feature-box" data-aos="fade-down">
                                <span>01</span>
                                <Icon icon="mage:email-notification" />
                                <p>Send<br />Enquiry</p>
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={4} xs={6}>
                            <div className="feature-box alt" data-aos="fade-down">
                                <span>02</span>
                                <Icon icon="material-symbols-light:order-approve-outline-rounded" />
                                <p>Get<br />Quote</p>
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={4} xs={6}>
                            <div className="feature-box" data-aos="fade-down">
                                <span>03</span>
                                <Icon icon="ph:seal-check" />
                                <p>Order<br />Placement</p>
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={4} xs={6}>
                            <div className="feature-box alt" data-aos="fade-down">
                                <a href="./assets/images/cad1.pdf" target="_blank" className="icon"><Icon icon="ri:question-line" /></a>                                
                                <span>04</span>
                                <Icon icon="hugeicons:drawing-compass" />
                                <p>Cad<br />Drawing</p>
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={4} xs={6}>
                            <div className="feature-box" data-aos="fade-down">
                                <span>05</span>
                                <Icon icon="carbon:settings-check" />
                                <p>Start<br />Production</p>
                            </div>
                        </Col>

                        <div className="w-100 d-none d-lg-block"></div>

                        <Col lg={2} md={4} sm={4} xs={6}>
                            <div className="feature-box alt" data-aos="fade-down">
                                <span>06</span>
                                <Icon icon="carbon:rule-data-quality" />
                                <p>Quality<br />Checking</p>
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={4} xs={6}>
                            <div className="feature-box" data-aos="fade-down">
                                <span>07</span>
                                <Icon icon="ph:images-light" />
                                <p>COMPLETED<br />PHOTOS</p>
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={4} xs={6}>
                            <div className="feature-box alt" data-aos="fade-down">
                                <span>08</span>
                                <Icon icon="ph:package-light" />
                                <p>Packing<br />&nbsp;</p>
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={4} xs={6}>
                            <div className="feature-box" data-aos="fade-down">
                                <span>09</span>
                                <Icon icon="ph:shipping-container" />
                                <p>Shipping<br />&nbsp;</p>
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={4} xs={6}>
                            <div className="feature-box alt" data-aos="fade-down">
                                <span>10</span>
                                <Icon icon="iconamoon:delivery-free-light" />
                                <p>Door<br />Delivery</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="shipping-desc">
                        <p>We handle <strong>shipping of your order</strong>, whether full container loads or part loads. We will arrange to deliver the crates directly to your address as per your requirements, using either a flatbed truck, a box truck with a liftgate, or directly in containers.</p>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default ProcessFlow;
