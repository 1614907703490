import { useEffect } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import { Tabs, Tab } from 'react-bootstrap';
import Header from "../components/Header";
import Footer from "../components/Footer";

const BookConsultation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    // Dynamically load Calendly script
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    script.onload = () => {
      console.log("Calendly script loaded");
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Function to initialize the Calendly popup widget
  // const openCalendlyPopup = (url) => {
  //   if (window.Calendly) {
  //     window.Calendly.initPopupWidget({ url });
  //   } else {
  //     console.error("Calendly script not loaded");
  //   }
  //   return false; // Prevent default anchor behavior
  // };

  return (
    <div>
      <Header />
      <section className="section inner">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading text-center">
                <h2 className="mb-4">Book a <span className="PinkText">Free<br/>Consultation</span> Call</h2>
                <p>No worries! Let’s connect and discuss your needs. Schedule a free 1-on-1 consultation call<br/> with our experts to explore how we can meet your requirements.</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col lg={9}>
              <Row>
                <Col lg={4} md={4}>
                  <div className="icon-widget text-center">
                    <span className="icon"><Icon icon="fluent:form-sparkle-20-regular" /></span>
                    <h6>Choose Time Zone</h6>
                    <p>This will help us align our schedules accurately</p>
                  </div>
                </Col>
                <Col lg={4} md={4}>
                  <div className="icon-widget text-center">
                    <span className="icon"><Icon icon="stash:calendar" /></span>
                    <h6>Schedule a Time</h6>
                    <p>Choose a convenient time for your consultation call.</p>
                  </div>
                </Col>
                <Col lg={4} md={4}>
                  <div className="icon-widget text-center">
                    <span className="icon"><Icon icon="fluent:person-support-24-regular" /></span>
                    <h6>Connect with Us</h6>
                    <p>Our expert will call you at the scheduled time.</p>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-md-center">
                <Col lg={10}>
                  <div className="mt-5">
                    <Tabs defaultActiveKey="usa" className="country_tabs" fill>
                      <Tab eventKey="usa" title={<span><img src='../assets/images/country/north-america.png' alt='usa'/> North America <label>Time Zone</label></span>}>
                        <div className="text-center">
                          <Button onClick={() => window.open('https://calendly.com/ravi-astronglobal/event', '_blank')} className="mt-4" variant="primary">
                            Schedule a Free Consultation
                          </Button>
                        </div>
                      </Tab>
                      <Tab eventKey="london" title={<span><img src='../assets/images/country/europe.png' alt='london'/> Europe <label>Time Zone</label></span>}>
                        <div className="text-center">
                          <Button onClick={() => window.open('https://calendly.com/ravi-astronglobal/astro-discovery-call', '_blank')} className="mt-4" variant="primary">
                            Schedule a Free Consultation
                          </Button>
                        </div>
                      </Tab>
                      <Tab eventKey="australia" title={<span><img src='../assets/images/country/anz.png' alt='australia'/> ANZ <label>Time Zone</label></span>}>
                        <div className="text-center">
                          <Button onClick={() => window.open('https://calendly.com/ravi-astronglobal/astron-discovery-call-australia', '_blank')} className="mt-4" variant="primary">
                            Schedule a Free Consultation
                          </Button>
                        </div>
                      </Tab>
                      {/* <Tab eventKey="newzealand" title={<span><img src='../assets/images/country/newzealand.svg' alt='newzealand'/> New Zealand <label>Time Zone</label></span>}>
                        <div className="text-center">
                          <Button onClick={() => openCalendlyPopup('https://calendly.com/ravi-astronglobal/event/astron-discovery-call-new-zealand')} className="mt-4" variant="primary">
                            Schedule a Free Consultation
                          </Button>
                        </div>
                      </Tab> */}
                    </Tabs>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default BookConsultation;
