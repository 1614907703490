import { useEffect } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { Icon } from '@iconify/react';
import OwlCarousel from 'react-owl-carousel';
// Add Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials";
import SampleForm from "../components/SampleForm";

const GetSample = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
      <Header />
      <section className="section inner">
        <Container>
          <Row>
            <Col lg={7} className="relative">
              <h1>Get your <span className="PinkText">Free Sample</span></h1>
              <p className="my-4">Experience our unparalleled product quality firsthand. We're excited to demonstrate why our craftsmanship stands out in the industry and earns your trust.</p>
              <h5>What Happens After You Submit the Form?</h5>
              <Row>
                <Col lg={4}>
                  <div className="icon-widget">
                    <span className="icon"><Icon icon="mage:email" /></span>
                    <p>You'll receive a confirmation email with the details of your sample request.</p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="icon-widget">
                    <span className="icon"><Icon icon="hugeicons:gift" /></span>
                    <p>Our team will process your request promptly, and your sample will be shipped within 5-7 business days.</p>
                  </div>
                </Col>
                <Col lg={4}>
                <div className="icon-widget">
                    <span className="icon"><Icon icon="fluent:person-support-24-regular" /></span>
                    <p>A dedicated account manager will be available to answer any questions.</p>
                  </div>
                </Col>
              </Row>
              <div className="direction-arrow"></div>
            </Col>
            <Col lg={5}>
              <SampleForm />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pb-5">
        <Container>
          <h2>Why Choose Us?</h2>
          <Row className="">
           <Col>
            <div className="why-widget">
              <span className="icon"><Icon icon="ant-design:file-protect-outlined" /></span>
              <h3>Fully<br/>Insured</h3>
              <p>Complete peace of mind</p>
            </div>
           </Col>
           <Col>
            <div className="why-widget">
              <span className="icon"><Icon icon="iconamoon:delivery-free-light" /></span>
              <h3>Doorstep<br/>Delivery</h3>
              <p>Fast and reliable</p>
            </div>
           </Col>
           <Col>
            <div className="why-widget">
              <span className="icon"><Icon icon="streamline:payment-10" /></span>
              <h3>Easy Payment<br/>Terms</h3>
              <p>Flexible and simple</p>
            </div>
           </Col>
           <Col>
            <div className="why-widget">
              <span className="icon"><Icon icon="iconoir:design-nib" /></span>
              <h3>Custom<br/>Designs</h3>
              <p>Tailored to you</p>
            </div>
           </Col>
           <Col>
            <div className="why-widget">
              <span className="icon"><Icon icon="mage:thumbs-up" /></span>
              <h3>Small Orders<br/>Welcome</h3>
              <p>All Sizes Accommodated</p>
            </div>
           </Col>
          </Row>
        </Container>
      </section>
      <section className="section inner alt-bg">
          <Container fluid>
            <Row>
                <Col lg={12}>
                    <div className="heading text-center mb-5">
                        <h2>Colors</h2>
                        <p>We are providing wide range of colors for your selection</p>
                    </div>
                </Col>
            </Row>              
          </Container>
          <Container>
            <OwlCarousel className="owl-theme material-slide" items={5} margin={15} autoplay={true} autoplayTimeout={2000} loop={true} nav={false} autoHeight={true} dots responsiveClass={true} responsive={{
                '0': { items: 2 },
                '768': { items: 4 },
                '990': { items: 6 },
            }}>
              <div className="hovershine">
                <figure><img src="../assets/images/materials/Bahama-Blue.jpg" alt="Bahama-Blue" className="img-fluid" /></figure>
                <span>Bahama Blue</span>
              </div>
              <div className="hovershine">
                <figure><img src="../assets/images/materials/Cats-Eye-Brown.jpg" alt="Cats-Eye-Brown" className="img-fluid" /></figure>
                <span>Cats Eye Brown</span>
              </div>
              <div className="hovershine">
                <figure><img src="../assets/images/materials/Himalaya.jpg" alt="Cats-Eye-Brown" className="img-fluid" /></figure>
                <span>Himalaya</span>
              </div>
              <div className="hovershine">
                <figure><img src="../assets/images/materials/India-Black.jpg" alt="Cats-Eye-Brown" className="img-fluid" /></figure>
                <span>India Black</span>
              </div>
              <div className="hovershine">
                <figure><img src="../assets/images/materials/India-Red.jpg" alt="Cats-Eye-Brown" className="img-fluid" /></figure>
                <span>India Red</span>
              </div>
              <div className="hovershine">
                <figure><img src="../assets/images/materials/Indian-Aurora.jpg" alt="Indian Aurora" className="img-fluid" /></figure>
                <span>Indian Aurora</span>
              </div>
              <div className="hovershine">
                <figure><img src="../assets/images/materials/indian-mahogany.jpg" alt="Emerald-Pearl" className="img-fluid" /></figure>
                <span>Indian Mahohany</span>
              </div>
              <div className="hovershine">
                <figure><img src="../assets/images/materials/Indian-Sadarali-Grey.jpg" alt="Himalaya" className="img-fluid" /></figure>
                <span>Indian Sadarli Grey</span>
              </div>
              <div className="hovershine">
                <figure><img src="../assets/images/materials/Kuppam-Green.jpg" alt="India-Black" className="img-fluid" /></figure>
                <span>Kuppam Green</span>
              </div>
              <div className="hovershine">
                <figure><img src="../assets/images/materials/Mist-Black.jpg" alt="India-Black" className="img-fluid" /></figure>
                <span>Mist Black</span>
              </div>
            </OwlCarousel>
          </Container>
        </section>

      <section className="consultation">
        <Container>
          <Row className="z-index">
           <Col lg={12}>
              <div className="heading light">
                <h2>Still Not Convinced<br/>to request a sample?</h2>
              </div>
              <h6 className="my-4">Book a Free Consultation Call</h6>
              <p>No worries! Let’s connect and discuss your needs. Schedule a free 1-on-1 consultation call<br/>
              with our experts to explore how we can meet your requirements.</p>
              <Button href="/book-a-consultation" className="mt-4" variant="primary">Schedule a Free Consultation</Button>
           </Col>
          </Row>
        </Container>
      </section>

      <Testimonials />
      <Footer />
    </div>
  );
};

export default GetSample;
