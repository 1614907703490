import React, { useEffect, useRef } from 'react';

const SampleForm = () => {
  const formContainerRef = useRef(null);

  useEffect(() => {
    const initializeScript = () => {
      const script = document.createElement("script");
      script.src = "https://astronglobal.myfreshworks.com/crm/sales/web_forms/0f60286c6e1d61619a4f438d0631875ed1ea98a714f412af7118aafcb6f29329/form.js";
      script.crossOrigin = "anonymous";
      script.id = "fs_0f60286c6e1d61619a4f438d0631875ed1ea98a714f412af7118aafcb6f29329";
  
      if (formContainerRef.current) {
        formContainerRef.current.appendChild(script);
      }
    };
  
    if (document.readyState === "complete") {
      initializeScript();
    } else {
      window.addEventListener("load", initializeScript);
      return () => window.removeEventListener("load", initializeScript);
    }
  }, []);
  

  return (
    <div className='contactForm formtablet'>
      <h1>Request Us</h1>
      <div ref={formContainerRef}></div>
    </div>
  );
};

export default SampleForm;
