import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon } from '@iconify/react';
import OwlCarousel from 'react-owl-carousel';

const Testimonials = () => {
    return (
        <>
            <section className="section testimonials alt-bg">
                <Container fluid="xl">
                    <Row>
                        <Col lg={12}>
                            <div className="heading text-center mb-5">
                                <h2>What our Clients Say</h2>
                                <p>We serve customers in various countries around the world.<br />If you have any specific questions or need assistance related to a particular country, feel free to ask!</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <OwlCarousel className="owl-theme" items={4} margin={15} autoplay={true} nav={false} autoHeight={true} dots responsiveClass={true} responsive={{
                                '0': { items: 1 },
                                '768': { items: 2 },
                                '990': { items: 4 },
                            }}>

                                <div className="testimonial">
                                    <div className="rating">
                                        <Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" />
                                    </div>
                                    <p>Astron Global’s service is top-notch, and their prices are very competitive. They provide excellent value, especially for smaller businesses. We love the added touch of the quality-approved stickers on each product!</p>
                                    <div className="client-info">
                                        <img src="../assets/images/country/usa.svg" alt="" className="img-fluid" />
                                        <p className="designation">United States of America</p>
                                    </div>
                                </div>

                                <div className="testimonial">
                                    <div className="rating">
                                        <Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" />
                                    </div>
                                    <p>At first, I was unsure about ordering online from a company in India, but Astron Global made it easy with their small order options and flexible payment terms. Their communication has been outstanding, and I’ve been impressed with their quick responses to all my requests.</p>
                                    <div className="client-info">
                                        <img src="../assets/images/country/canada.svg" alt="" className="img-fluid" />
                                        <p className="designation">Canada</p>
                                    </div>
                                </div>

                                <div className="testimonial">
                                    <div className="rating">
                                        <Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" />
                                    </div>
                                    <p>We’ve partnered with Astron Global for over 20 years and have imported more than 90 loads. We’ve always been happy with their service, and they’ve proven to be a reliable supplier time and time again.</p>
                                    <div className="client-info">
                                        <img src="../assets/images/country/newzealand.svg" alt="" className="img-fluid" />
                                        <p className="designation">New Zealand</p>
                                    </div>
                                </div>

                                <div className="testimonial">
                                    <div className="rating">
                                        <Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" />
                                    </div>
                                    <p>Receiving my second order from Astron Global was a breeze. Everything from the product quality to the direct-to-door delivery has been exactly what we needed. Importing monuments has never been this easy!</p>
                                    <div className="client-info">
                                        <img src="../assets/images/country/ireland.svg" alt="" className="img-fluid" />
                                        <p className="designation">Ireland</p>
                                    </div>
                                </div>

                                <div className="testimonial">
                                    <div className="rating">
                                        <Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" />
                                    </div>
                                    <p>Astron Global has made ordering stress-free! Their prompt responses, clear CAD drawings, and photos of the finished products before dispatch make the whole process smooth. I wouldn’t hesitate to recommend them to others.</p>
                                    <div className="client-info">
                                        <img src="../assets/images/country/uk.svg" alt="" className="img-fluid" />
                                        <p className="designation">United Kingdom</p>
                                    </div>
                                </div>

                                <div className="testimonial">
                                    <div className="rating">
                                        <Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" />
                                    </div>
                                    <p>I was very impressed with the first order of monuments from Astron Global. The packing was secure, and the quality was excellent. I’m excited to continue working with them and look forward to many more orders.</p>
                                    <div className="client-info">
                                        <img src="../assets/images/country/germany.svg" alt="" className="img-fluid" />
                                        <p className="designation">Germany</p>
                                    </div>
                                </div>
                                
                                <div className="testimonial">
                                    <div className="rating">
                                        <Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" /><Icon icon="mingcute:star-fill" />
                                    </div>
                                    <p>Astron Global provided us with top-quality granite and craftsmanship. We’ve been really happy with the service and are confident in their ability to meet our needs every time.</p>
                                    <div className="client-info">
                                        <img src="../assets/images/country/australia.svg" alt="" className="img-fluid" />
                                        <p className="designation">Australia</p>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Testimonials;
